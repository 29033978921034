import React from 'react'

export default {
  title_pt: 'Rotesma - Sobre',
  title_en: 'Rotesma - About',
  title_es: 'Rotesma - Sobre',
  locations_pt: <><span>Rotesma é</span>Três unidades estrategicamente localizadas</>,
  locations_en: <><span>Rotesma é</span>Três unidades estrategicamente localizadas</>,
  locations_es: <><span>Rotesma cuenta con</span>Tres plantas de producción estratégicamente localizadas ubicadas</>,
  locations_description_pt: <>Três indústrias divididas entre Santa Catarina, Paraná e Paraguai, que juntos possuem uma área total de <b>150 mil m²</b>, para uma produção eficiente que entregou <b>1484 obras</b> nos últimos 11 anos.<br/><br/>Atendemos em 4 estados do Brasil:<br/><b>RS, SC, PR, SP e também no Paraguai.</b></>,
  locations_description_en: <>Três indústrias divididas entre Santa Catarina, Paraná e Paraguai, que juntos possuem uma área total de <b>150 mil m²</b>, para uma produção eficiente que entregou <b>1484 obras</b> nos últimos 11 anos.<br/><br/>Atendemos em 4 estados do Brasil:<br/><b>RS, SC, PR, SP e também no Paraguai.</b></>,
  locations_description_es: <>Tres industrias divididas entre Santa Catarina, Paraná y Paraguay, que juntas suman un área total de <b>150 mil m²</b>, para una producción eficiente que entregó <b>1.484 obras</b> en los últimos 11 años.<br/><br/>Atendemos demandas en 4 estados del Brasil <br/><b>(RS, SC, PR, SP) y también en Paraguay.</b></>,
  team_pt: <><span>Rotesma é</span>Uma equipe conectada em 100% do processo</>,
  team_en: <><span>Rotesma é</span>Uma equipe conectada em 100% do processo</>,
  team_es: <><span>Rotesma es</span>Un equipo conectado en el 100% del proceso.</>,
  team_item_1_pt: <>Única do segmento com uma equipe de <b>26 engenheiros</b>, entre comercial, engenharia, produção e montagem, fazendo com que em todos os processos Rotesma exista um profissional de engenharia envolvido.</>,
  team_item_1_en: <>Única do segmento com uma equipe de <b>26 engenheiros</b>, entre comercial, engenharia, produção e montagem, fazendo com que em todos os processos Rotesma exista um profissional de engenharia envolvido.</>,
  team_item_1_es: <>Única en el rubro con un equipo de <b>26 ingenieros</b> en las ramas civil, de producción, comercial, logística y montaje, permitiendo que todos los procesos acompañados por un profesional de ingeniería.</>,
  team_item_2_pt: <>São <b>+ de 450 funcionários</b> 100% conectados através de uma tecnologia de gerenciamento ligada a todos os processos e equipes.</>,
  team_item_2_en: <>São <b>+ de 450 funcionários</b> 100% conectados através de uma tecnologia de gerenciamento ligada a todos os processos e equipes.</>,
  team_item_2_es: <>Más de <b>450 empleados</b> están 100% conectados a través de tecnología de gestión vinculada a todos los procesos y equipos.</>,
  team_item_3_pt: <>O cuidado na seleção de matérias primas é visível, uma peça Rotesma valoriza estruturas aparentes na sua arquitetura. <b>Estruturas maciça e sem imperfeições.</b></>,
  team_item_3_en: <>O cuidado na seleção de matérias primas é visível, uma peça Rotesma valoriza estruturas aparentes na sua arquitetura. <b>Estruturas maciça e sem imperfeições.</b></>,
  team_item_3_es: <>Se nota el cuidado en la selección de las materias primas, una pieza de Rotesma valora estructuras aparentes en su arquitectura. <b>Estructuras sólidas e impecables.</b></>
}
