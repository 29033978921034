import React, { useState } from 'react'
import { ApiHook, Footer, Grid, Head, Slider, Translate } from '@components'
import Lightbox from 'lightbox-react'
import locale from '@data/locales/about'
import { graphql, useStaticQuery } from 'gatsby'
import * as st from '@assets/styl/About.module.styl'
import map from '@assets/svg/mapa.svg'

function pad(n: number, size: number) {
  let str = n.toString()
  while (str.length < size) str = '0' + str
  return str
}

export default ({ location: { pathname } }) => {
  const query = useStaticQuery(graphql`
    query {
      allAddresses {
        nodes {
          image
          zoom
          city
          uf
        }
      }
    }
  `)
  const [zoomObj, setZoomObj] = useState<{ index: number, items?: Array<string> }>({ index: 0 })
  const locations = ApiHook(query.allAddresses.nodes, 'addresses', array => array.map(({ city, uf, image, zoom }) => ({ name: 'Rotesma', city: `${city} ${uf}`, image, zoom: zoom || image })))
  function page(slide: number, add: number = 1) {
    let p = 0
    if (slide >= locations.length) p = add
    else if (slide < 0) p = locations.length - 1 + add
    else p = slide + add
    return p % locations.length || locations.length
  }
  const [slide, setSlide] = useState(0)

  return <>
    {Translate(locale, 'title', title => <Head title={title} path={pathname} />)}

    <section className={st.locations} id="about-locations"><div>
      <Grid className={st.grid}>
        <div>
          <h3>{Translate(locale, 'locations')}</h3>
          <p>{Translate(locale, 'locations_description')}</p>
          <img src={map} />
          <ul className={st.pages}>
            <li>
              <span>{pad(page(slide), 2)}.</span>
              <ul>
                {locations.map(({ name, city }, key) => <li key={key} className={slide === key || (slide >= locations.length && key === 0) || (slide < 0 && key === locations.length-1) ? st.visible : undefined}>
                  {name}<br/>{city}
                </li>)}
              </ul>
            </li>
            {locations.slice(1, 3).map((_, key) => <li key={key} onClick={() => setSlide(page(slide, key + 2) - 1)}>{pad(page(slide, key + 2), 2)}.</li>)}
          </ul>
          <div className={st.controls}>
            <button className="icon-arrow" onClick={() => setSlide(slide - 1)}></button>
            <button className="icon-arrow" onClick={() => setSlide(slide + 1)}></button>
          </div>
          <Slider goToSlide={setSlide} currentSlide={slide} slidesToScroll={1} className={st.slider} prepend={2}>
            {locations.map(({ image }, key) => <div key={key} className={st.slide} onClick={() => setZoomObj({ index: key, items: locations.map(i => i.zoom) })}>
              <div style={{ backgroundImage: `url(${image})` }}></div>
            </div>)}
          </Slider>
        </div>
        <div><img src={map} /></div>
      </Grid>
    </div></section>

    <section className={st.team} id="about-team">
      <Grid className={st.grid}>
        <h3>{Translate(locale, 'team')}</h3>
        <ul>
          <li>
            <span className="icon-helmet"></span>
            <div>{Translate(locale, 'team_item_1')}</div>
          </li>
          <li>
            <span className="icon-worker"></span>
            <div>{Translate(locale, 'team_item_2')}</div>
          </li>
          <li>
            <span className="icon-struct"></span>
            <div>{Translate(locale, 'team_item_3')}</div>
          </li>
        </ul>
      </Grid>
    </section>

    <Footer />

    {zoomObj.items && <Lightbox mainSrc={zoomObj.items[zoomObj.index]} onCloseRequest={() => setZoomObj({ index: 0 })}
      prevSrc={zoomObj.items[(zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length]}
      nextSrc={zoomObj.items[(zoomObj.index + 1) % zoomObj.items.length]}
      onMovePrevRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + zoomObj.items.length - 1) % zoomObj.items.length })}
      onMoveNextRequest={() => setZoomObj({ ...zoomObj, index: (zoomObj.index + 1) % zoomObj.items.length })} />}
  </>
}